<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="disc">
        <div class="disc_box">
            <div class="disc_box_left">
                <!-- <el-date-picker
                    v-model="time"
                    class="purchasing_box_left_sj"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker> -->
                <div class="supplierprice_tit_box_left_lishi">
                    <p>
                        <img src="../../../assets/images/lishichaxun.png" alt="">
                        盘点查询
                    </p>
                    <p v-if="chooseCn">盘点号: {{chooseCn}}</p>
                    <div class="rli_box">
                        <el-calendar class="supplierprice_rli" @click.native='dianji_xuanze_rli' ref="rli">
                            <!-- 这里使用的是 2.5 slot 语法，对于新项目请使用 2.6 slot 语法-->
                            <template
                                slot="dateCell"
                                slot-scope="{date, data}"
                                
                                >
                                <div class="text">{{ data.day.split('-').slice(2).join('') }}
                                    <div v-for="(item,index) in rqi_list" :key="index">
                                        <span class="lvse" v-if="data.day==item" ></span>
                                        <!-- <div v-if="(item.months).indexOf(data.day.split('-').slice(1)[0])!=-1">
                                            <div v-if="(item.days).indexOf(data.day.split('-').slice(2).join('-'))!=-1">
                                                <span></span>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </template>
                        </el-calendar>
                    </div>
                </div>
                <!-- <div style="margin:0 0.2rem;" v-if="lishi_rqi.length!=0">
                    盘点号：
                    <div>{{chooseCn}}</div>
                </div> -->
                <div class="xuanze" v-show="zhanshi_fanye">
                    产品类别
                    <el-tooltip class="item" effect="dark" :content="dangqian_xuanze_chanpin.map(item=>item.prod_name).join(',')" placement="top-start">
                        <p @click="dianji_xuanze_chanpin">{{dangqian_xuanze_chanpin.length!=0?(dangqian_xuanze_chanpin.map(item=>item.prod_name).join(',')):'请选择'}}</p>
                    </el-tooltip>
                </div>
                <div class="xuanze" v-show="zhanshi_fanye">
                    仓库
                    <el-tooltip class="item" effect="dark" :content="dangqian_xuanze_cangku.map(item=>item.label).join(',')" placement="top-start">
                        <p @click="dianji_xuanze_cangku">{{dangqian_xuanze_cangku.length!=0?(dangqian_xuanze_cangku.map(item=>item.label).join('-')):'请选择'}}</p>
                    </el-tooltip>
                </div>
                <!-- <ul class="sqe_tit">
                    <li @click="dianji_btn(i)" v-for="i in anniu_list" :key="i.id" class="zise">
                        {{i.name}}
                    </li>
                </ul> -->
                <el-button class="btnB" :disabled="disabled1" @click="sureList">确定盘点列表</el-button>
                <el-button class="btnB" :disabled="disabled2" @click="exportP">导出待盘点信息</el-button>
                <el-button class="btnB" :disabled="disabled3" @click="setList">填写盘点结果</el-button>
                <el-button class="btnB" :disabled="disabled4" @click="saveList">最终保存</el-button>
                <el-button class="btnB" :disabled="disabled5" @click="exportp">导出盘点结果</el-button>
                <el-button class="btnB" :disabled="disabled6" @click="exportt" v-if="zhan">展示差异</el-button>
                <el-button class="btnB" :disabled="disabled6" @click="exporttt" v-if="!zhan">展示全部</el-button>
            </div>
        </div>
        <div class="gongying_shang_table">
            <el-table
              ref="gongying_shang_ref"
              :data="gongying_shang_list"
              :cell-style="liebiao"
              :header-cell-style="biaotou"
              tooltip-effect="dark"
              height="6.5rem"
              style="width: 100%;font-size: 14px"
            >
                <el-table-column label="图片" prop="prod_pic">
                  <template slot-scope="props">
                    <img :src="props.row.prod_pic" style="width: 50px;height: 50px;" v-if="props.row.prod_pic">
                    <div v-else>-</div>
                  </template>
                </el-table-column>
                <el-table-column label="产品" prop="prod_name" min-width="110"></el-table-column>
                <el-table-column label="产品编号" prop="prod_code"></el-table-column>
                <el-table-column label="仓库" prop="site_name"></el-table-column>
                <el-table-column label="规格" prop="spec"></el-table-column>
                <el-table-column label="型号" prop="type"></el-table-column>
                <el-table-column label="产品单价">
                  <template slot-scope="props">
                    {{props.row.price_unit_end?props.row.price_unit_end:props.row.price_unit}}
                  </template>
                </el-table-column>
                <el-table-column
                    label="库存"
                    >
                    <el-table-column
                      label="数量"
                    >
                      <template slot-scope="props">
                        {{props.row.qty_end?props.row.qty_end:props.row.qty}}
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="金额"
                    >
                      <template slot-scope="props">
                        {{props.row.amt_end?props.row.amt_end:props.row.amt}}
                      </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column
                    label="盘点数"
                    width="130"
                    prop="qty_check"
                    >
                  <template slot-scope="props">
                    <input
                      type="number"
                      placeholder="请输入"
                      v-if="inputTy == 1"
                      style="text-align:center;width:1rem;"
                      v-model="props.row.qty_check"
                      @blur="handleInput(props.row)"
                    >
                    <div v-else>{{props.row.qty_check}}</div>
                  </template>
                </el-table-column>
                <el-table-column
                    label="盘点差异"
                    >
                    <el-table-column
                        label="数量"
                        prop="qty_diff"
                        >
                    </el-table-column>
                    <el-table-column
                        label="金额"
                        prop="amt_diff"
                        >
                    </el-table-column>
                </el-table-column>
                <el-table-column
                    label="备注"
                    >
                    <template slot-scope="props">
                        <input v-show="inputTy == 1" class="pandian_shu" style="text-align:right;width:0.5rem;" type="text" v-model="props.row.remarks" placeholder="请填写">
                        <!-- {{props.row.amt_end}} -->
                        <span v-show="inputTy == 0">{{props.row.remarks}}</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-pagination
            v-show="zhanshi_fanye"
            @size-change="dianji_tiaoshu"
            @current-change="dianji_yeshu"
            :current-page="dangqian_yeshu"
            class="fenye_qi"
            :page-sizes="[10, 20, 30, 40,50,60,70,80,90,100]"
            :page-size='tiaoshu'
            layout="total,sizes, prev, pager, next, jumper"
            :total="gongying_shang_list.length">
        </el-pagination>
        <shaixuan ref="xuanze" @sousuo='sousuo_chanpin' @dalei='dianji_dalei' @shaixuan_queding='dianji_queding'></shaixuan>
        <zujianDuoxuan ref="cangku" @shuxing='chuanzhi'></zujianDuoxuan>
        <div class='beijing' v-if='chanpin' @click="chanpin=false">
            <div class="beijing_box" @click.stop>
                <i class="el-icon-error guanbi_xuanze_chanpin" @click="chanpin=false"></i>
                <div class='beijing_box_tit'>
                    产品分类：
                    <div class="beijing_box_tit_div">
                        <p class="beijing_box_tit_p">{{dangqian_chanpin.label}}</p>
                        <div class="beijing_box_tit_tree">
                            <el-tree :data="qiye_chanpin_leixing_tree" :props="tree_guize" @node-click="xuanze_chanpin_dalei"></el-tree>
                        </div>
                    </div>
                    搜索：
                    <p class="shiyong_sousuo_chanpin">
                        <i class="el-icon-search"></i>
                        <input v-model="sousuo_chanpin_text" @keyup.enter="chanpin_sousuo()" type="text">
                        <i @click="sousuo_chanpin_text='',chanpin_sousuo()" class='el-icon-circle-close'></i>
                    </p>
                    <span class="shiyong_chanpin_queding" @click="dianji_xuanze_chanpin_baocun()">确定</span>
                </div>
                <ul class="mingxi_chanpin_yangshi">
                    <li v-for='(cp,ind) in dangqian_mingxi_kuang_chanpin' :key="cp.prod_code">
                        <i @click="shanchu_yixuan_chanpin(cp,ind)" class="el-icon-error"></i>
                        {{cp.prod_name}}
                    </li>
                </ul>
                <div class='beijing_box_table'>
                    <el-table :cell-style="liebiao_yangshi" height="500" :header-cell-style="biaotou_yangshi" :data="chanpin_liebiao_list"
                        highlight-current-row
                        ref="Tablea"
                        style="width: 100%"
                        @select = "xuanze_chanpin"
                        @select-all = "xuanze_chanpin_quanxuan"
                        >
                        <el-table-column  type="selection" width="42">
                        </el-table-column>
                        <el-table-column  prop="prod_code" label="产品编号">
                        </el-table-column>
                        <el-table-column  prop="prod_name" label="产品">
                        </el-table-column>
                        <el-table-column prop="cat_name"  label="类型">
                        </el-table-column>
                        <el-table-column  prop="spec" label="规格">
                        </el-table-column>
                        <el-table-column  prop="type" label="型号">
                        </el-table-column>
                        <el-table-column  prop="unit" label="单位">
                        </el-table-column>
                        <el-table-column  prop="price_basic" label="初始价">
                        </el-table-column>
                        <!-- <el-table-column  prop="qty" label="数量">
                        </el-table-column> -->
                        <!-- <el-table-column  prop="price_cost" label="成本" width="80">
                        </el-table-column>
                        <el-table-column  prop="price_sale" label="售价" width="80">
                        </el-table-column> -->
                        <el-table-column  prop="disc_rate" label="调价率">
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <el-dialog
          title="选择盘点号"
          :visible.sync="dialogVisiblea"
        >
          <el-radio-group v-model="chooseCn">
            <el-radio v-for="(item, index) in checkNo" :key="index" :label="item" :value="item">{{item}}</el-radio>
          </el-radio-group>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisiblea = false">取 消</el-button>
            <el-button type="primary" @click="checkNoSure">确 定</el-button>
          </span>
        </el-dialog>
    </div>
</template>

<script>
import { query_all_prod_list_of_category, query_ent_prod_cat_list_tree, save_stock_check_info, query_stock_check_his_date_list, supply_stock_check_list, query_category_info_list, query_prod_info_list, get_prod_list_like, query_supply_list, query_ent_site_tree, query_stock_check_list, query_check_no_list, save_pre_stock_check_info } from '../../../api/api'

import shaixuan from '../../../components/shaixuan/Duoxuan'
import zujianDuoxuan from '../../../components/zujian/Tree'
export default {
  name: 'disc',
  data () {
    return {
      disabled1: false,
      disabled2: false,
      zhan: true,
      disabled3: false,
      disabled4: true,
      disabled5: true,
      disabled6: true,
      check_no: '',
      ty: true,
      stack: '',
      gongying_shang_list: [],
      pandian: 0,
      chooseCn: '',
      dialogVisiblea: false,
      xuan_zhong: '',
      dangqian_yeshu: 1,
      dangqian_zongshu: 0,
      tiaoshu: 10,
      checkNo: [],
      youce_xiangqing: false,
      sousuo_text: '',
      time: [],

      lishi_rqi: '', // 历史日期
      rqi_list: [],

      dangqian_xuanze_chanpin: [],
      dangqian_xuanze_cangku: [],

      zhanshi_fanye: true, // true盘点列表  false获取盘点信息
      pandian_leixing: 1, // true 1全部    false2差异

      // url:'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/',//服务器2号机
      // url:'https://www.mlcbr.com:8443/hwb_client/V1.0.0/',
      url: 'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/',
      //  url:'http://192.168.3.5:8080/hwb_client/V1.0.0/',

      // 产品选择修改选择样式    2020、11、11   啊哈哈  光棍节快乐啊QwQ
      chanpin: false,
      dangqian_chanpin: {
        label: '',
        id: ''
      },
      checkp: [],
      inputTy: null,
      status: [],
      qiye_chanpin_leixing_tree: [], // 产品大类树
      sousuo_chanpin_text: '', // 搜索产品text
      chanpin_xuanze: '',
      chanpin_liebiao_list: [],
      tree_guize: {
        children: 'children',
        label: 'label'
      },
      dangqian_mingxi_kuang_chanpin: []

    }
  },
  mounted () {
  },
  components: { shaixuan, zujianDuoxuan },
  created () {
    this.inputTy = null
    this.pandian = 0
    this.liebiao_jiekou()
    this.chaxun_rqi_liebiao()
    // query_check_no_list({
    //     data: {
    //       ent_id: this.$ent_id(),
    //       user_id: this.$jichuxinxi().user_id,
    //       prod_ids: this.dangqian_xuanze_chanpin.length != 0 ? this.dangqian_xuanze_chanpin.map(item => item.prod_id).join(',') : null,
    //       site_ids: this.dangqian_xuanze_cangku.length != 0 ? this.dangqian_xuanze_cangku.map(item => item.id).join(',') : null,
    //       date_start: this.lishi_rqi.length != 0 ? this.lishi_rqi : null,
    //       date_end: this.lishi_rqi.length != 0 ? this.lishi_rqi : null,
    //       search_str: this.sousuo_text.length != 0 ? this.sousuo_text : null,
    //     }
    //   })
    //   .then(res => {
    //     this.checkNo = []
    //     console.log(res)
    //     this.checkNo = JSON.parse(res.data.body.data)
    //     console.log(this.checkNo)
    //   })
  },
  watch: {
    dangqian_xuanze_chanpin () {
      this.liebiao_jiekou()
    },
    dangqian_xuanze_cangku () {
      this.liebiao_jiekou()
    },
    chanpin () {
      if (!this.chanpin) {
        this.dangqian_chanpin = {
          label: '',
          id: ''
        }
        this.sousuo_chanpin_text = ''
        this.chanpin_xuanze = ''
        this.chanpin_liebiao_list = []
        this.dangqian_mingxi_kuang_chanpin = []
      }
    }
  },
  methods: {
    // 表头颜色
    biaotou (row) { // 根据报警级别显示颜色
      return 'text-align:center;color:#1A2533;font-size:0.14rem;'
    },
    // color:#4C4A4D;font-size:0.12rem;
    liebiao (row) {
      return 'text-align:center;color:#1A2533;'
    },
    // hangdian (val) {
    //   console.log(val)
    // },
    dianji_yeshu (val) {
      this.dangqian_yeshu = val
    },
    dianji_tiaoshu (val) {
      console.log(`每页 ${val} 条`)
      this.tiaoshu = val
      this.dangqian_yeshu = 1
    },
    liebiao_jiekou () {
      let param = {
        ent_id: this.$ent_id(),
        user_id: this.$jichuxinxi().user_id,
        prod_ids: this.dangqian_xuanze_chanpin.length != 0 ? this.dangqian_xuanze_chanpin.map(item => item.prod_id).join(',') : null,
        site_ids: this.dangqian_xuanze_cangku.length != 0 ? this.dangqian_xuanze_cangku.map(item => item.id).join(',') : null,
        date_start: this.lishi_rqi.length != 0 ? this.lishi_rqi : null,
        date_end: this.lishi_rqi.length != 0 ? this.lishi_rqi : null,
        search_str: this.sousuo_text.length != 0 ? this.sousuo_text : null,
        page_size: this.tiaoshu + '',
        page_index: this.dangqian_yeshu + '',
        check_type: this.pandian_leixing ? '1' : '2', // 1全部2差异
      }
      console.log(param)
      query_stock_check_list({
        data: param
      }).then(res => {
        //this.$refs.buttonn[5].disabled = 'true'
        console.log(res, 'wrsd')
        if (res.data.code == 200) {
          const data = JSON.parse(res.data.body.data).list
          console.log(data)
          this.disabled4 = true
          // data.forEach(item => {
          //   this.checkNo.push(item.check_no)
          // })
          // this.checkNo = [...new Set(this.checkNo)]
        } else if (res.data.code == 500) {
          alert('系统报错')
        }
      })
      supply_stock_check_list({
        data: {
          ent_id: this.$ent_id(),
          prod_ids: this.dangqian_xuanze_chanpin.length != 0 ? this.dangqian_xuanze_chanpin.map(item => item.prod_id).join(',') : null,
          site_ids: this.dangqian_xuanze_cangku.length != 0 ? this.dangqian_xuanze_cangku.map(item => item.id).join(',') : null,
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          this.chooseCn = res.data.body.check_no
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.gongying_shang_list = date
          this.stack = res.data.body.status_check
          if(res.data.body.status_check == 1) {
            this.gongying_shang_list.forEach(item => {
              item.qty_end = item.qty
              item.amt_end = item.amt
            })
            this.disabled1 = true
            this.disabled2 = false
            this.disabled3 = false
            this.disabled4 = true
            this.disabled5 = true
            this.disabled6 = true
          } else if(res.data.body.status_check == 0){
            if(this.gongying_shang_list[0].amt_diff) {
              this.disabled1 = true
              this.disabled2 = true
              this.disabled3 = true
              this.disabled4 = true
              this.disabled5 = false
              this.disabled6 = false
            } else {
              this.disabled1 = false
              this.disabled2 = true
              this.disabled3 = true
              this.disabled4 = true
              this.disabled5 = true
              this.disabled6 = true
            }
          }
        } else if (res.data.code == 500) {}
      })
    },
    fujian_xiazai (i) {
      this.$func.fujian_xiazai2(i)
    },
    dianji_xiazai_fujian (i) {
      this.$func.fujian_xiazai(i)
    },
    dianji_xuanze_rli () {
      //console.log(this.$refs.rli.formatedDate)
      this.lishi_rqi = this.$refs.rli.formatedDate
      query_check_no_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          prod_ids: this.dangqian_xuanze_chanpin.length != 0 ? this.dangqian_xuanze_chanpin.map(item => item.prod_id).join(',') : null,
          site_ids: this.dangqian_xuanze_cangku.length != 0 ? this.dangqian_xuanze_cangku.map(item => item.id).join(',') : null,
          date_start: this.lishi_rqi,
          date_end: this.lishi_rqi,
          search_str: this.sousuo_text.length != 0 ? this.sousuo_text : null,
        }
      })
      .then(res => {
        this.dialogVisiblea = true
        this.checkNo = []
        console.log(res)
        this.checkp = JSON.parse(res.data.body.data)
        JSON.parse(res.data.body.data).forEach(item => {
          this.checkNo.push(item.check_no)
        })
        JSON.parse(res.data.body.data).forEach(item => {
          this.status.push(item.status_check)
        })
        console.log(this.checkNo)
      })
    },
    // 点击选择产品
    dianji_xuanze_chanpin () {
      // this.chanpin_dalei(null)
      this.chanpin = true
      query_ent_prod_cat_list_tree({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.qiye_chanpin_leixing_tree = date
        } else if (res.data.code == 500) {}
      })
      query_prod_info_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          cat_id: '0',
          active: '1'
        }
      }).then(res => {
        if (res.data.code == 10139) {
          const date = JSON.parse(res.data.body.data)
          this.no_xuan_chanpin(date)
        } else if (res.data.code == 10140) {
        }
      })
      this.dangqian_mingxi_kuang_chanpin = this.dangqian_xuanze_chanpin
    },
    chanpin_dalei (parent_id, yixuan) {
      if (yixuan != undefined && yixuan != null) {
      } else {
        yixuan = this.dangqian_xuanze_chanpin
      }
      query_category_info_list({
        data: {
          active: '1',
          ent_id: this.$ent_id(),
          parent_id: parent_id != null ? parent_id : '0',
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10193) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.chanpin_xiaolei(parent_id, date, yixuan)
        } else if (res.data.code == 10194) {}
      })
    },
    chanpin_xiaolei (parent_id, parent, yixuan) {
      query_prod_info_list({
        data: {
          active: '1',
          cat_id: parent_id != null ? parent_id : '0',
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10139) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.$refs.xuanze.jichu('选择产品', parent, date, yixuan, 'cat_name', 'cat_id', 'prod_name', 'prod_id')
          this.$refs.xuanze.bangding('dangqian_xuanze_chanpin')
        } else if (res.data.code == 10140) {}
      })
    },
    sousuo_chanpin (val) {
      console.log(val.text)
      if (val.bangding_ziduan == 'dangqian_xuanze_chanpin') {
        get_prod_list_like({
          data: {
            active: '1',
            ent_id: this.$ent_id(),
            prod_name: val.text.length != 0 ? val.text : null,
            user_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10031) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.$refs.xuanze.sousuo_xiaolei(date, 'prod_name', 'prod_id')
          } else if (res.data.code == 10032) {}
        })
      } else {
        query_supply_list({
          data: {
            ent_id: this.$ent_id(),
            search_str: val.text.length != 0 ? val.text : null
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            this.$refs.xuanze.sousuo_xiaolei(date, 'supply_name', 'supply_id')
          } else if (res.data.code == 500) {
            alert('查询供应商list报错')
          }
        })
      }
    },
    // 点击大类
    dianji_dalei (data) {
      this.chanpin_dalei(data.id, data.yixuan)
    },
    dianji_queding (val) {
      console.log(val)
      this[val.ziduan] = val.list
    },
    // 点击选择供应商
    dianji_xuanze_gongying_shang (yixuan) {
      if (yixuan != undefined && yixuan != null) {
      } else {
        yixuan = this.dangqian_gongying_shang
      }
      this.gongying_shang_xiaolei(yixuan)
    },
    gongying_shang_xiaolei (yixuan) {
      query_supply_list({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          this.$refs.xuanze.jichu('选择供应商', null, date, yixuan, null, null, 'supply_name', 'supply_id')
          this.$refs.xuanze.bangding('dangqian_gongying_shang')
        } else if (res.data.code == 500) {
          alert('查询供应商list报错')
        }
      })
    },
    dianji_jinru_kucun () {
      this.$router.push('/inventorytou')
    },
    // 点击选择仓库
    dianji_xuanze_cangku () {
      query_ent_site_tree({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.$refs.cangku.jichu(date, '仓库选择', this.dangqian_xuanze_cangku, '多选', '有关联')
        } else if (res.data.code == 500) {}
      })
    },
    chuanzhi (val) {
      console.log(val)
      this.dangqian_xuanze_cangku = val
      console.log(this.dangqian_xuanze_cangku)
    },
    sureList() {
      this.pandian = 0
      this.ty = true
      this.disabled2 = false
      this.disabled3 = false
      this.gongying_shang_list.forEach(item => {
        if(!('price_unit' in item)) {
          item.price_unit = item.price_unit_end
        }
        if(!('qty' in item)) {
          item.qty = item.qty_end
        }
        if(!('amt' in item)) {
          item.amt = item.amt_end
        }
      })
      save_pre_stock_check_info({
        data: {
          ent_id: this.$ent_id(),
          check_no: this.chooseCn,
          list: this.gongying_shang_list
        }
      }).then(res => {
        if(res.data.code == 200) {
          this.$message.success('确定成功')
          this.chooseCn = res.data.body.check_no
          this.disabled1 = true
          this.disabled6 = true
          this.disabled5 = true
          this.disabled4 = true
        }
      })
    },
    exportP() {
      let date = this.url + 'export_pre_stock_check_list?ent_id=' + this.$ent_id() + "&check_no=" + this.chooseCn
      this.$func.multiDownLoad([date])
    },
    setList() {
      this.inputTy = 1
      this.disabled4 = false
    },
    saveList() {
      let list = []
      this.gongying_shang_list.forEach(item => {
        if(!('amt' in item)) {
          item.amt = item.amt_end
        }
        if(!('price_unit' in item)) {
          item.price_unit = item.amt_end
        }
        if(!('qty' in item)) {
          item.qty = item.qty_end
        }
      })
      save_stock_check_info({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          list: this.gongying_shang_list,
          check_no: this.chooseCn
        }
      }).then(res => {
        if (res.data.code == 200) {
          this.$message({
            message: '保存成功',
            type: 'success'
          })
        }
        this.inputTy = null
        this.disabled4 = true
        this.disabled5 = false
        this.disabled6 = false
        this.disabled3 = false
        this.disabled1 = false
        this.disabled2 = true
        this.liebiao_jiekou()
      })
    },
    exportp() {
      this.disabled4 = false
      this.disabled6 = false
      let datee = ''
      let date = this.url + 'export_stock_check_list?ent_id=' + this.$ent_id() + '&check_no=' + this.chooseCn
      console.log(date)
      this.$func.multiDownLoad([date])
    },
    exportt() {
      let arr = this.gongying_shang_list
      arr  =  arr.filter((item,index)=>{
          if(item.amt_diff!=='0.00'&&item.qty_diff!=='0.00'){
            return true
          }
        })
      console.log(arr)
      this.gongying_shang_list = arr
      this.zhan = false
    },
    exporttt() {
      let statuss = ''
      this.checkp.forEach(item => {
        if(item.check_no == this.chooseCn) {
          statuss = item.status_check
        }
      })
      let param = {
        ent_id: this.$ent_id(),
        user_id: this.$jichuxinxi().user_id,
        prod_ids: this.dangqian_xuanze_chanpin.length != 0 ? this.dangqian_xuanze_chanpin.map(item => item.prod_id).join(',') : null,
        site_ids: this.dangqian_xuanze_cangku.length != 0 ? this.dangqian_xuanze_cangku.map(item => item.id).join(',') : null,
        date_start: this.lishi_rqi.length != 0 ? this.lishi_rqi : null,
        date_end: this.lishi_rqi.length != 0 ? this.lishi_rqi : null,
        search_str: this.sousuo_text.length != 0 ? this.sousuo_text : null,
        page_size: this.tiaoshu + '',
        page_index: this.dangqian_yeshu + '',
        check_type: '1', // 1全部2差异
        check_no: this.chooseCn,
        status_check: statuss
      }
      query_stock_check_list({
        data: param
      }).then(res => {
        console.log(res, 'wrsd')
        if (res.data.code == 200) {
          const datea = JSON.parse(res.data.body.data).list
          console.log(datea)
          this.gongying_shang_list = datea
          this.zhan = true
          this.disabled5 = false
          this.disabled6 = false
        } else if (res.data.code == 500) {
          alert('系统报错')
        }
      })
    },
      // if (i.id == 2) { // 点击进行保存
      //   console.log(this.gongying_shang_list)
      //   console.log(this.gongying_shang_list, 11111)
      //   const list = []
      //   this.gongying_shang_list.forEach(item => {
      //     console.log(item)
      //     if (item.qty_check.length != 0) {
      //       this.$set(item, 'price_unit', item.price_unit_end)
      //       this.$set(item, 'qty', item.qty_end)
      //       this.$set(item, 'amt', item.amt_end)
      //       list.push(item)
      //     }
      //   })
      //   save_stock_check_info({
      //     data: {
      //       ent_id: this.$ent_id(),
      //       user_id: this.$jichuxinxi().user_id,
      //       list: list
      //     }
      //   }).then(res => {
      //     console.log(res)
      //     if (res.data.code == 200) {
      //       this.$message({
      //         message: '保存成功',
      //         type: 'success'
      //       })
      //       this.pandian = 1
      //       this.zhanshi_fanye = true // 展示翻页
      //       this.liebiao_jiekou(this.pandian)
      //     } else if (res.data.code == 500) {}
      //   })
      // }

    // dianji_jisuan (date) {
    //   this.$set(date, 'qty_diff', Number(date.qty_check) - Number(date.qty_end))
    //   this.$set(date, 'amt_diff', ((Number(date.price_unit_end) * Number(date.qty_check)) - Number(date.amt_end)).toFixed(2))
    // },
    // rqi_list
  chaxun_rqi_liebiao () {
    query_stock_check_his_date_list({
      data: {
        ent_id: this.$ent_id()
      }
    }).then(res => {
      console.log(res)
      if (res.data.code == 200) {
        const date = JSON.parse(res.data.body.data)
        console.log(date)
        this.rqi_list = date
      } else if (res.data.code == 500) {}
    })
  },
  no_xuan_chanpin (date) {
    this.chanpin_liebiao_list = date
    this.$forceUpdate()
  },
    // 2020、6、10更改产品样式   2020/11/10修改传值
    xuanze_chanpin_dalei (val) {
      console.log(val)
      this.dangqian_chanpin = val
      if (this.dangqian_chanpin.id.length != 0) {
        query_all_prod_list_of_category({
          data: {
            ent_id: this.$ent_id(),
            cat_id: this.dangqian_chanpin.id + ''
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            // let date=JSON.parse(res.data.body.data)
            console.log(this.buneng_xuan_chanpin)
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.no_xuan_chanpin(date)
            // var b = this.buneng_xuan_chanpin
            // let deal = (arr1, arr2, key) => {
            //     let arr3 = []
            //     for (let index = 0; index < arr1.length; index++) {
            //         let item = arr1[index]
            //         if (!arr2.some(v => v[key] === item[key])) {
            //             arr3.push(item)
            //         }
            //     }
            //     return arr3
            // }
            // console.log(deal(a,b,'prod_id'))
            // this.chanpin_liebiao_list=deal(a,b,'prod_id')
            // console.log(this.chanpin_liebiao_list)
            // this.$forceUpdate()
            if (this.dangqian_mingxi_kuang_chanpin.length != 0) {
              this.$nextTick(function () {
                this.chanpin_liebiao_list.forEach(item => {
                  this.dangqian_mingxi_kuang_chanpin.forEach(ite => {
                    if (ite.prod_id == item.prod_id) {
                      console.log(item)
                      this.$refs.Tablea.toggleRowSelection(item)
                    }
                  })
                })
              })
            }
            this.$forceUpdate()
          } else if (res.data.code == 500) {}
        })
      }
    },
    chanpin_sousuo () {
      // sousuo_chanpin_text
      if (this.sousuo_chanpin_text.length != 0) {
        get_prod_list_like({
          data: {
            active: '1',
            ent_id: this.$ent_id(),
            prod_name: this.sousuo_chanpin_text.length != 0 ? this.sousuo_chanpin_text : null,
            user_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10031) {
            const date = JSON.parse(res.data.body.data)
            this.no_xuan_chanpin(date)
            // for(let i=0;i<date.length;i++){
            //     for(let a=0;a<this.buneng_xuan_chanpin.length;a++){
            //         if(date[i].prod_id==this.buneng_xuan_chanpin[a]){
            //             date.splice(i,1)
            //             i=i-1
            //         }
            //     }
            // }
            // this.chanpin_liebiao_list=date
            // console.log(this.chanpin_liebiao_list)
            this.$forceUpdate()
          } else if (res.data.code == 10032) {}
        })
      } else {
        if (this.dangqian_chanpin.id.length != 0) {
          query_all_prod_list_of_category({
            data: {
              ent_id: this.$ent_id(),
              cat_id: this.dangqian_chanpin.id + ''
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              this.no_xuan_chanpin(date)
              // console.log(date)
              // console.log(this.buneng_xuan_chanpin)
              // for(let i=0;i<date.length;i++){
              //     for(let a=0;a<this.buneng_xuan_chanpin.length;a++){
              //         if(date[i].prod_id==this.buneng_xuan_chanpin[a]){
              //             date.splice(i,1)
              //             i=i-1
              //         }
              //     }
              // }
              // this.chanpin_liebiao_list=date
              // console.log(this.chanpin_liebiao_list)
              // this.$forceUpdate()
            } else if (res.data.code == 500) {}
          })
        } else {
          this.chanpin_liebiao_list = []
        }
      }
    },
    dianji_xuanze_chanpin_baocun () {
      console.log(this.dangqian_mingxi_kuang_chanpin)
      console.log(this.suoxuan_list)
      this.dangqian_xuanze_chanpin = this.dangqian_mingxi_kuang_chanpin
      this.chanpin = false
    },
    liebiao_yangshi (row) {
      return 'color:#4C4A4D;font-size:0.1rem;'
    },
    biaotou_yangshi (row) {
      return 'color:#1A2533;'
    },
    dianji_xuanzhong_chanpin (val) {
      this.chanpin_xuanze = val
    },

    shanchu_yixuan_chanpin (i, index) {
      this.chanpin_liebiao_list.forEach(ite => {
        if (ite.prod_id == i.prod_id) {
          this.$refs.Tablea.toggleRowSelection(ite)
        }
      })
      this.dangqian_mingxi_kuang_chanpin.splice(index, 1)
    },
    xuanze_chanpin (rows, row) {
      console.log(rows)
      console.log(row)
      const ses = rows.length && rows.indexOf(row) !== -1 // 判断是否打钩
      if (this.dangqian_mingxi_kuang_chanpin.length != 0) {
        if (ses) {
          this.dangqian_mingxi_kuang_chanpin.push(row)
        } else {
          for (let i = 0; i < this.dangqian_mingxi_kuang_chanpin.length; i++) {
            if (this.dangqian_mingxi_kuang_chanpin[i].prod_id == row.prod_id) {
              this.dangqian_mingxi_kuang_chanpin.splice(i, 1)
              i = i - 1
            }
          }
        }
      } else {
        this.dangqian_mingxi_kuang_chanpin = rows
      }
      // return rows.length && rows.indexOf(row) !== -1
    },
    xuanze_chanpin_quanxuan (rows, row) {
      console.log(rows)
      if (rows.length != 0) {
        rows.forEach(item => {
          this.dangqian_mingxi_kuang_chanpin.push(item)
        })
        this.dangqian_mingxi_kuang_chanpin = this.$func.Es5duplicate(this.dangqian_mingxi_kuang_chanpin, 'prod_id')
      } else {
        // chanpin_liebiao_list
        // this.dangqian_mingxi_kuang_chanpin
        for (let i = 0; i < this.dangqian_mingxi_kuang_chanpin.length; i++) {
          // this.chanpin_liebiao_list.forEach(item=>{
          for (let a = 0; a < this.chanpin_liebiao_list.length; a++) {
            if (this.dangqian_mingxi_kuang_chanpin[i].prod_id == this.chanpin_liebiao_list[a].prod_id) {
              this.dangqian_mingxi_kuang_chanpin.splice(i, 1)
              i = i - 1
              break
            }
          }
          // })
        }
      }
    },
    // getCheckNo(val) {
    //   //console.log(val)
     
    //   query_check_no_list({
    //     data: {
    //       ent_id: this.$ent_id(),
    //       user_id: this.$jichuxinxi().user_id,
    //       prod_ids: this.dangqian_xuanze_chanpin.length != 0 ? this.dangqian_xuanze_chanpin.map(item => item.prod_id).join(',') : null,
    //       site_ids: this.dangqian_xuanze_cangku.length != 0 ? this.dangqian_xuanze_cangku.map(item => item.id).join(',') : null,
    //       date_start: val,
    //       date_end: val,
    //       search_str: this.sousuo_text.length != 0 ? this.sousuo_text : null,
    //     }
    //   })
    //   .then(res => {
    //     this.dialogVisiblea = true
    //     this.checkNo = []
    //     console.log(res)
    //     this.checkNo = JSON.parse(res.data.body.data)
    //     console.log(this.checkNo)
    //   })
    //   //this.liebiao_jiekou(0)
    // },
    checkNoSure() {
      this.dialogVisiblea = false
      let statuss = ''
      this.checkp.forEach(item => {
        if(item.check_no == this.chooseCn) {
          statuss = item.status_check
        }
      })
      console.log(statuss)
      let param = {
        ent_id: this.$ent_id(),
        user_id: this.$jichuxinxi().user_id,
        prod_ids: this.dangqian_xuanze_chanpin.length != 0 ? this.dangqian_xuanze_chanpin.map(item => item.prod_id).join(',') : null,
        site_ids: this.dangqian_xuanze_cangku.length != 0 ? this.dangqian_xuanze_cangku.map(item => item.id).join(',') : null,
        date_start: this.lishi_rqi.length != 0 ? this.lishi_rqi : null,
        date_end: this.lishi_rqi.length != 0 ? this.lishi_rqi : null,
        search_str: this.sousuo_text.length != 0 ? this.sousuo_text : null,
        page_size: this.tiaoshu + '',
        page_index: this.dangqian_yeshu + '',
        check_type: this.pandian_leixing ? '1' : '2', // 1全部2差异
        check_no: this.chooseCn,
        status_check: statuss
      }
      query_stock_check_list({
              data: param
            }).then(res => {
              //this.$refs.buttonn[5].disabled = 'true'
              console.log(res, 'wrsd')
              if (res.data.code == 200) {
                const data = JSON.parse(res.data.body.data).list
                console.log(data)
                this.gongying_shang_list = data
                if(statuss == 0) {
                  this.gongying_shang_list.forEach(item => {
                    if(!('qty_end') in item) {
                      item.qty_end = item.qty
                    }
                    if(!('amt_end') in item) {
                      item.amt_end = item.amt
                    }
                  })
                  this.disabled1 = true
                  this.disabled2 = false
                  this.disabled3 = false
                  this.disabled4 = true
                  this.disabled5 = true
                  this.disabled6 = true
                  console.log(this.gongying_shang_list)
                } else {
                  this.disabled1 = true
                  this.disabled2 = true
                  this.disabled3 = true
                  this.disabled4 = true
                  this.disabled5 = false
                  this.disabled6 = false
                  this.gongying_shang_list.forEach(item => {
                    if(!('qty_end') in item) {
                      item.qty_end = item.qty
                    }
                    if(!('amt_end') in item) {
                      item.amt_end = item.amt
                    }
                  })
                }
                // this.dangqian_xuanze_chanpin = []
                // this.dangqian_xuanze_cangku = []
                // data.forEach(item => {
                //   this.checkNo.push(item.check_no)
                // })
                // this.checkNo = [...new Set(this.checkNo)]
              } else if (res.data.code == 500) {
                alert('系统报错')
              }
            })
    },
    handleInput(item) {
      console.log(item.qty_check)
      console.log(item.price_unit_end)
      if(item.qty_end && item.amt_end){
        this.$set(item, 'qty_diff', Number(item.qty_check) - Number(item.qty_end))
        this.$set(item, 'amt_diff', ((Number(item.price_unit) * Number(item.qty_check)) - Number(item.amt_end)).toFixed(2))
      } else {
        this.$set(item, 'qty_diff', Number(item.qty_check) - Number(item.qty))
        this.$set(item, 'amt_diff', ((Number(item.price_unit) * Number(item.qty_check)) - Number(item.amt)).toFixed(2))
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 @import '../chanpin.scss';
 @import '../../../style/salesman.scss';
 .btnB{
    height: 0.4rem;
    padding: 0 0.2rem;
    border-radius: 0.08rem;
    line-height: 0.4rem;
    text-align: center;
    cursor: pointer;
    background: #8041d8;
    color: #fff;
    border: 1px solid #979797;
    margin-left: 0.3rem;
    box-shadow: 0 0 0.1rem #8041d8;
    border: 1px solid #8041d8;
    outline: none;
 }
</style>
